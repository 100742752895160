<script lang="ts" setup>
import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { defineAsyncComponent, type PropType } from 'vue'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      route: getServerState('route'),
    }

// Define Props
defineProps({
  visible: {
    type: Boolean as PropType<boolean>,
    default: () => false,
  },
  subMenu: {
    type: Array as PropType<Array<{ text: string, url: string, touch?: boolean }>>,
    default: () => ([]),
  },
  articles: {
    type: Array as PropType<Array<{ text: string, url: string, touch?: boolean }>>,
    default: () => ([]),
  },
})

const ZgIcon = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgIcon))

defineEmits(['track-navigation-click'])

const filteredItems = (items: Array<{ text: string, url: string, touch?: boolean }>) => {
  return items?.slice(0, 18)?.filter(item => !item.touch && !!item.text) ?? []
}
</script>

<template>
  <div v-if="visible" class="sub-menus">
    <div v-if="subMenu.length" :class="['sub-menu', { 'sub-menu--with-border': articles.length }]">
      <a
        v-for="(item, index) in filteredItems(subMenu)"
        :key="`sub-menu-${index}`"
        :href="item.url"
        :class="{ 'current': ctx?.route?.pathname === item.url }"
        tabindex="0"
        @click="$emit('track-navigation-click', item.url)"
      >
        {{ item.text }}
      </a>
    </div>

    <div v-if="articles.length" class="articles">
      <a
        v-for="(item, index) in filteredItems(articles)"
        :key="`article-${index}`"
        :href="item.url"
        :class="{ 'current': ctx?.route?.pathname === item.url }"
        tabindex="0"
        @click="$emit('track-navigation-click', item.url)"
      >
        {{ item.text }}
        <zg-icon icon="arrow-forward" size="xsmall" />
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .sub-menus {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: row;
    background-color: var(--beige);
    padding: rem(16px) 0;
    border-radius: 0 0 rem(20px) rem(20px);
  }

  .sub-menu {
    &--with-border {
      border-right: rem(1px) solid var(--gray-200);
    }

    a {
      @include type-title-xs;
    }
  }

  .articles {
    a {
      @include type-title-xxs;
    }
  }

  .sub-menu,
  .articles {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    a {
      color: var(--black);
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      gap: rem(8px);
      border: none;
      white-space: nowrap;
      padding: rem(14px) rem(24px);
      margin: 0 rem(16px) 0 0;
      cursor: pointer;
      border-radius: 0 rem(99px) rem(99px) 0;

      &:hover {
        color: var(--brand-500);

        svg {
          color: var(--brand-500);
        }
      }

      &.current {
        background: var(--white);
      }
    }
  }
</style>
